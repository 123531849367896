import {
  Box,
  Button,
  Clickable,
  Dark,
  PopoverBody,
  PopoverContainer,
  PopoverFooter,
  PopoverHeader,
  PopoverRoot,
  PopoverTrigger,
  Text,
} from '@mentimeter/ragnar-ui';
import { animated, type SpringValues } from 'react-spring';
import React, { useState, forwardRef } from 'react';
import {
  QuestionMarkCircleIcon,
  StarSolidIcon,
} from '@mentimeter/ragnar-visuals';
import type { TrackingContext } from '@mentimeter/http-clients';
import { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { shade } from '@mentimeter/ragnar-colors';
import { useProgressBarAnimation } from '../use-progress-bar-animation';
import { useHasSeenBefore } from '../modals/useHasSeenBefore';
import { useFeatureAccess } from '../../lib/hooks/use-feature-access';
import {
  requestUpgrade,
  RequestUpgradeButton,
} from '../../lib/request-upgrade';
import type { UpgradeEvent } from '../../types';
import { UpgradeButton } from '../../buttons/UpgradeButton';
import { RequestUpgradeModalContent } from '../../lib/request-upgrade/modal/RequestUpgradeModalContent';

const ALIGN_POPOVER_TO_BANNER = 30;
const ALIGN_POPOVER_TO_BUTTON = 116;

export const BannerProgressBar = forwardRef<
  HTMLDivElement,
  {
    progress: number;
    limit: number;
    onClick: () => void;
  }
>(({ progress, limit, onClick }, ref) => {
  const { style, progressByLimit } = useProgressBarAnimation(progress, limit);

  return (
    <Box
      ref={ref}
      width="100%"
      alignItems="stretch"
      borderRadius="xl"
      py="space3"
      px="space4"
      onClick={onClick}
      extend={({ theme }) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: `${shade(theme.colors.highContrast, theme.colors.onHighContrast, 0.1)}`,
        },
      })}
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="row" alignItems="baseline" mr="space2">
          <Text fontWeight="bold" color="onHighContrast" fontSize="87.5">
            {progress}/{limit}
          </Text>
        </Box>
        <Text color="onHighContrast" fontSize="87.5">
          Participants this month
        </Text>
        <Clickable ml="space1">
          <QuestionMarkCircleIcon color="onHighContrast" />
        </Clickable>
      </Box>
      <Box height="6px" position="relative" mt="space1.5">
        <Box
          bg="onHighContrast"
          extend={({ theme }) => ({
            opacity: 0.2,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: `${theme.radii[1]}px`,
          })}
        />
        <Box
          data-progress={progressByLimit}
          bg="onHighContrast"
          extend={({ theme }) => ({
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: `${theme.radii[1]}px`,
          })}
          as={animated.div}
          style={style as SpringValues}
        />
      </Box>
    </Box>
  );
});

export function NormalHelpBox({
  resetsAt,
  progress,
  limit,
  children,
  upgradeEvent,
  trackingContext,
  isMemberLite,
}: {
  resetsAt: string;
  progress: number;
  limit: number;
  children: React.ReactNode;
  upgradeEvent: UpgradeEvent;
  trackingContext: TrackingContext | undefined;
  isMemberLite: boolean;
}) {
  const { hasSeenBefore } = useHasSeenBefore(
    `banner-progress-popover-activated`,
  );
  const [open, setOpen] = useState(!hasSeenBefore);
  const [requestUpgradeModalOpen, setRequestUpgradeModalOpen] = useState(false);
  const accessLevels = useFeatureAccess(UserFeaturesTypeEnum.BASIC);

  return (
    <RequestUpgradeModalContent
      withTrigger={false}
      open={requestUpgradeModalOpen}
      onOpenChange={setRequestUpgradeModalOpen}
      onRequestUpgrade={async (message) => {
        requestUpgrade({
          message,
          trackingContext,
          trackingPlacement: 'Participant progress normal popover',
        });
      }}
    >
      <PopoverRoot open={open} onOpenChange={setOpen}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContainer
          overflow="visible"
          showArrow={false}
          width={400}
          sideOffset={ALIGN_POPOVER_TO_BANNER}
          mr={ALIGN_POPOVER_TO_BUTTON}
          mt="-space3"
        >
          <Dark>
            <Box bg="surface" borderRadius="lg">
              <FakeArrow />
              <PopoverHeader>You can still present this month</PopoverHeader>
              <PopoverBody>
                {isMemberLite ? (
                  <Text>
                    Your free limit resets {resetsAt}. You can go over the limit
                    once every month, or you can request an upgrade now.
                  </Text>
                ) : (
                  <>
                    <Text>
                      On free, you can present to 50 participants each month. If
                      more people join a live presentation and this limit is
                      exceeded, you won’t be interrupted.
                    </Text>
                    <Text mt="space3">
                      You have presented to {progress}/{limit} participants this
                      month. This resets on {resetsAt}.
                    </Text>
                  </>
                )}
              </PopoverBody>
              <PopoverFooter>
                {isMemberLite ? (
                  <RequestUpgradeButton
                    variant="secondary"
                    iconLeading={undefined}
                    onClick={handleHelBoxUpgradeClick}
                  />
                ) : (
                  <UpgradeButton
                    variant="secondary"
                    onClick={handleHelBoxUpgradeClick}
                    iconLeading={undefined}
                  />
                )}
                <Button variant="primary" onClick={() => setOpen(false)}>
                  Got it
                </Button>
              </PopoverFooter>
            </Box>
          </Dark>
        </PopoverContainer>
      </PopoverRoot>
    </RequestUpgradeModalContent>
  );

  function handleHelBoxUpgradeClick() {
    if (accessLevels === 'not-in-role') {
      setOpen(false);
      setRequestUpgradeModalOpen(true);
    } else {
      trackUser({
        event: upgradeEvent,
        properties: {
          context: trackingContext,
          placement: 'Participant progress normal popover',
        },
      });
    }
  }
}

export function ReachedLimitHelpBox({
  showAutomatically = true,
  resetsAt,
  progress,
  limit,
  children,
  upgradeEvent,
  trackingContext,
  onClickLearnMore,
  isMemberLite,
}: {
  showAutomatically?: boolean;
  resetsAt: string;
  progress: number;
  limit: number;
  children: React.ReactNode;
  upgradeEvent: UpgradeEvent;
  trackingContext: TrackingContext | undefined;
  onClickLearnMore(): void;
  isMemberLite: boolean;
}) {
  const { hasSeenBefore } = useHasSeenBefore(`banner-progress-popover-reached`);
  const [open, setOpen] = useState(!hasSeenBefore && showAutomatically);
  const [requestUpgradeModalOpen, setRequestUpgradeModalOpen] = useState(false);
  const accessLevels = useFeatureAccess(UserFeaturesTypeEnum.BASIC);

  const extraProgress = Math.max(0, progress - limit);
  const extraText =
    extraProgress > 1 ? (
      <> — the extra {extraProgress} people are on us</>
    ) : null;

  return (
    <RequestUpgradeModalContent
      withTrigger={false}
      open={requestUpgradeModalOpen}
      onOpenChange={setRequestUpgradeModalOpen}
      onRequestUpgrade={async (message) => {
        requestUpgrade({
          message,
          trackingContext,
          trackingPlacement: 'Participant progress reached limit popover',
        });
      }}
    >
      <PopoverRoot open={open} onOpenChange={setOpen}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContainer
          overflow="visible"
          showArrow={false}
          width={400}
          sideOffset={ALIGN_POPOVER_TO_BANNER}
          mt="-space3"
        >
          <Dark>
            <Box bg="surface" borderRadius="lg">
              <FakeArrow />
              <PopoverHeader>Want to reach more people?</PopoverHeader>
              <PopoverBody>
                {isMemberLite ? (
                  <Text>
                    Reach out to your admin for an upgrade to engage more people
                    every month, or wait until your participation limit resets
                    on {resetsAt}.
                  </Text>
                ) : (
                  <Text>
                    You engaged with {progress} participants this month
                    {extraText}. Upgrade for unlimited participants, or wait
                    until your participation limit resets on {resetsAt}.
                  </Text>
                )}
              </PopoverBody>
              <PopoverFooter>
                <Button
                  ml="space8"
                  href="https://help.mentimeter.com/en/articles/1258367-what-is-included-in-the-free-account"
                  variant="secondary"
                  target="_blank"
                  onClick={onClickLearnMore}
                >
                  Learn more
                </Button>
                {isMemberLite ? (
                  <RequestUpgradeButton
                    variant="positive"
                    iconLeading={StarSolidIcon}
                    onClick={handleHelBoxUpgradeClick}
                  />
                ) : (
                  <UpgradeButton
                    variant="positive"
                    onClick={handleHelBoxUpgradeClick}
                    iconLeading={StarSolidIcon}
                  />
                )}
              </PopoverFooter>
            </Box>
          </Dark>
        </PopoverContainer>
      </PopoverRoot>
    </RequestUpgradeModalContent>
  );

  function handleHelBoxUpgradeClick() {
    if (accessLevels === 'not-in-role') {
      setOpen(false);
      setRequestUpgradeModalOpen(true);
    } else {
      trackUser({
        event: upgradeEvent,
        properties: {
          context: trackingContext,
          placement: 'Participant progress reached limit popover',
        },
      });
    }
  }
}

function FakeArrow() {
  return (
    <Box
      width={10}
      height={10}
      bg="surface"
      position="absolute"
      left="94%"
      extend={() => ({
        transform: 'translate(-50%, -50%) rotate(45deg)',
      })}
    />
  );
}
