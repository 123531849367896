import { type UserT } from '@mentimeter/user';
import { differenceInDays } from 'date-fns';

const NEW_USER_DAYS_THRESHOLD = 28;

export function isNewUser(createdAt: UserT['createdAt']) {
  return (
    differenceInDays(new Date(), new Date(createdAt)) < NEW_USER_DAYS_THRESHOLD
  );
}
