import { TrackingContext } from './constants';

export function contextFromPathname(
  pathname: string,
): TrackingContext | undefined {
  if (pathname.startsWith('/app/home')) return TrackingContext.UserHome;
  if (pathname.startsWith('/app/dashboard'))
    return TrackingContext.MyPresentations;
  if (pathname.startsWith('/app/folder'))
    return TrackingContext.MyPresentations;
  if (pathname.startsWith('/app/workspace'))
    return TrackingContext.WorkspacePresentations;
  if (pathname.startsWith('/app/shared-with-me'))
    return TrackingContext.SharedWithMe;
  if (pathname.startsWith('/app/settings')) return TrackingContext.Settings;
  if (pathname.startsWith('/app/manage-members'))
    return TrackingContext.ManageMembers;
  if (pathname.startsWith('/app/billing')) return TrackingContext.Billing;
  if (pathname.startsWith('/app/find-your-team'))
    return TrackingContext.FindAvailableTeams;
  if (pathname.startsWith('/app/workspace-settings'))
    return TrackingContext.WorkspaceSettings;
  if (pathname.startsWith('/app/trash')) return TrackingContext.Trash;
  if (pathname.startsWith('/app/templates'))
    return TrackingContext.TemplatesOverviewPage;
  if (pathname.startsWith('/app/shared'))
    return TrackingContext.SharedTemplates;

  return undefined;
}
