import { useUser, type UserT } from '@mentimeter/user';
import {
  EngagementStatus,
  useEngagementLimits,
} from '@mentimeter/paywalls-data-hooks';
import { isNewUser } from './isNewUser';

export function shouldShowEngagementLimitBanner(
  userCreatedAt: UserT['createdAt'],
  engagementStatus: EngagementStatus,
) {
  if (!isNewUser(userCreatedAt)) {
    return engagementStatus >= EngagementStatus.Unengaged;
  } else {
    return engagementStatus >= EngagementStatus.ActivatedLimit;
  }
}

export function useShouldShowEngagementLimitBanner() {
  const { user } = useUser();
  const engagementLimits = useEngagementLimits();

  return (
    engagementLimits &&
    user &&
    shouldShowEngagementLimitBanner(
      user.createdAt,
      engagementLimits.engagementStatus,
    )
  );
}
