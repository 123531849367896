import { type ButtonT, Button } from '@mentimeter/ragnar-ui';
import { StarSolidIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';

export type UpgradeButtonDataHandler = ButtonT & {
  text?: string;
};

export function UpgradeButton({
  text = 'Upgrade',
  ...buttonProps
}: UpgradeButtonDataHandler) {
  return (
    <Button
      href="/plans"
      variant="positive"
      target="_blank"
      iconLeading={StarSolidIcon}
      {...buttonProps}
    >
      {text}
    </Button>
  );
}
