import React from 'react';
import { useUser } from '@mentimeter/user';
import type { TrackingContext } from '@mentimeter/http-clients';
import { UserFeaturesTypeEnum } from '@mentimeter/http-clients';
import { StarSolidIcon } from '@mentimeter/ragnar-visuals';
import { trackUser } from '@api/tracking/client';
import {
  RequestUpgradeModal,
  RequestUpgradeButton,
} from '../lib/request-upgrade';
import { useFeatureAccess } from '../lib/hooks/use-feature-access';
import type { UpgradeEvent } from '../types';
import { UpgradeButton } from './UpgradeButton';

export function EngagementBannerUpgradeButton({
  upgradeEvent,
  trackingContext,
  showStarIcon = false,
}: {
  upgradeEvent: UpgradeEvent;
  trackingContext: TrackingContext | undefined;
  showStarIcon?: boolean;
}) {
  const { user } = useUser();
  const accessLevels = useFeatureAccess(UserFeaturesTypeEnum.BASIC);

  if (!user || accessLevels === 'has-access') return null;

  if (accessLevels === 'not-in-role') {
    return (
      <RequestUpgradeModal
        trackingContext={trackingContext}
        trackingPlacement="Engagement limits banner"
      >
        <RequestUpgradeButton iconLeading={StarSolidIcon} variant="positive" />
      </RequestUpgradeModal>
    );
  } else {
    if (showStarIcon) {
      return <UpgradeButton onClick={trackClickUpgrade} />;
    } else {
      return (
        <UpgradeButton onClick={trackClickUpgrade} iconLeading={undefined} />
      );
    }
  }

  function trackClickUpgrade() {
    trackUser({
      event: upgradeEvent,
      properties: {
        context: trackingContext,
        placement: 'Engagement limits banner',
      },
    });
  }
}
