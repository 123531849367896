import { useEffect, useRef } from 'react';
import { LocalStorage } from '@mentimeter/storage';

const KEEP_OPEN_TIME = 3 * 1000;
const CYCLE_LENGTH = 28 * 60 * 60 * 1000;

export function useHasSeenBefore(key: string) {
  const lastOpened = useRef(
    LocalStorage.getJSONItem<number>(`modal-seen:${key}`),
  );

  useEffect(() => {
    const now = Date.now();

    if (!shouldMarkAsOpened(lastOpened.current, now, CYCLE_LENGTH)) return;

    LocalStorage.setJSONItem<number>({
      type: 'functional',
      key: `modal-seen:${key}`,
      value: now,
    });
  }, [key]);

  return {
    hasSeenBefore: !shouldShow(
      lastOpened.current,
      Date.now(),
      KEEP_OPEN_TIME,
      CYCLE_LENGTH,
    ),
  };
}

export function shouldShow(
  lastOpened: number | null,
  now: number,
  keepOpenTime: number = 10 * 1000,
  cycleLength: number = CYCLE_LENGTH,
) {
  if (lastOpened === null) return true;

  const timeSinceLastOpened = now - lastOpened;
  return (
    timeSinceLastOpened < keepOpenTime || timeSinceLastOpened > cycleLength
  );
}

export function shouldMarkAsOpened(
  lastOpened: number | null,
  now: number,
  cycleLength: number = CYCLE_LENGTH,
) {
  if (lastOpened === null) return true;

  const timeSinceLastOpened = now - lastOpened;
  return timeSinceLastOpened > cycleLength;
}
