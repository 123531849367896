'use client';

import { useMatch } from '@mentimeter/ragnar-device';

export function Mobile({ children }: { children: React.ReactNode }) {
  const isMobile = useMatch({ lessThan: 3 });

  if (!isMobile) return null;
  return children;
}

export function Desktop({ children }: { children: React.ReactNode }) {
  const isDesktop = useMatch({ greaterThan: 2 });

  if (!isDesktop) return null;
  return children;
}
