import type { TrackingContext } from '@mentimeter/http-clients';
import { getIsMemberLite, useUser } from '@mentimeter/user';
import {
  EngagementStatus,
  EngagementStatusNames,
  useEngagementLimits,
} from '@mentimeter/paywalls-data-hooks';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { EngagementLimits } from '@mentimeter/paywalls-data-hooks';
import { trackUser } from '@api/tracking/client';
import { EngagementBannerUpgradeButton } from '../../../buttons';
import { shouldShowEngagementLimitBanner } from '../../shouldShowEngagementLimitBanner';
import type { UpgradeEvent } from '../../../types';

export function NewEngagementLimitBannerDataHandler({
  upgradeEvent,
  trackingContext,
  children,
}: {
  upgradeEvent: UpgradeEvent;
  trackingContext: TrackingContext | undefined;
  children: ({
    engagementLimits,
  }: {
    engagementLimits: EngagementLimits;
    trackClickProgressBar: () => void;
    trackClickLearnMore: () => void;
    isMemberLite: boolean;
  }) => React.ReactNode;
}) {
  const { user } = useUser();
  const engagementLimits = useEngagementLimits();
  const isMemberLite = getIsMemberLite(user);

  if (!user || !engagementLimits) return null;
  if (
    !shouldShowEngagementLimitBanner(
      user.createdAt,
      engagementLimits.engagementStatus,
    )
  )
    return null;

  const {
    engagementStatus,
    engagementData: { progress },
  } = engagementLimits;
  const { title, description } = getTexts(engagementStatus);

  return (
    <Box height="72px" position="relative" width="100%">
      <Box position="fixed" width="100%" height="72px" zIndex={4}>
        <Box
          position="relative"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={['row-reverse', 'row-reverse', 'row-reverse', 'row']}
          width="100%"
          height="100%"
          px={3}
          bg="highContrast"
        >
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box display={['none', 'none', 'none', 'inline']}>
              <Text fontWeight="semiBold" color="onHighContrast">
                {title}
              </Text>
              <Text ml="space1" color="onHighContrast">
                {description}
              </Text>
            </Box>
            <Box ml="space4" display={['none', 'flex', 'flex']}>
              <EngagementBannerUpgradeButton
                upgradeEvent={upgradeEvent}
                trackingContext={trackingContext}
                showStarIcon
              />
            </Box>
          </Box>

          <Box
            ml="space2"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            {children({
              engagementLimits,
              trackClickProgressBar,
              trackClickLearnMore,
              isMemberLite,
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  function trackClickProgressBar() {
    trackUser({
      event: 'Clicked engagement limit progress bar',
      properties: {
        context: trackingContext,
        placement: 'Engagement limits banner',
        engagement_status: EngagementStatusNames[engagementStatus],
        engaged_participants: progress,
      },
    });
  }

  function trackClickLearnMore() {
    trackUser({
      event: 'Clicked engagement limit learn more',
      properties: {
        context: trackingContext,
        placement: 'Engagement limits banner',
        engagement_status: EngagementStatusNames[engagementStatus],
        engaged_participants: progress,
      },
    });
  }
}

function getTexts(engagementStatus: EngagementStatus) {
  return engagementStatus === EngagementStatus.ReachedLimit
    ? {
        title: 'You’ve reached your participant limit for this month.',
        description: 'Upgrade for unlimited participants and more.',
      }
    : {
        title: `Unlock unlimited participants to reach more people.`,
      };
}
