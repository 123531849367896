import React from 'react';
import { contextFromPathname } from '@mentimeter/http-clients';
import { useExperiment } from '@mentimeter/splitio';
import { EngagementStatus } from '@mentimeter/paywalls-data-hooks';
import { usePathname } from '@mentimeter/next-navigation';
import {
  BannerProgressBar,
  NormalHelpBox,
  ReachedLimitHelpBox,
} from '../progress-bar/BannerProgressBar';
import { getResetsAtText } from '../../lib/hooks/get-resets-at-text';
import { CurrentEngagementLimitBanner } from './current/CurrentEngagementLimitBanner';
import { NewEngagementLimitBannerDataHandler } from './new/NewEngagementLimitDataHandler';

export function DashboardEngagementLimitBanner() {
  const { isInExperiment: isInNewLimitBannerExperiment } = useExperiment(
    'PUG_New_Limit_Experience',
    ['new-banner'],
  );
  const pathname = usePathname();
  const trackingContext = contextFromPathname(pathname);

  if (isInNewLimitBannerExperiment) {
    return (
      <NewEngagementLimitBannerDataHandler
        upgradeEvent="Plans from homeview"
        trackingContext={trackingContext}
      >
        {({
          engagementLimits: {
            engagementData: { resetsAt, progress, limit },
            engagementStatus,
          },
          trackClickProgressBar,
          isMemberLite,
          trackClickLearnMore,
        }) =>
          engagementStatus === EngagementStatus.ReachedLimit ? (
            <ReachedLimitHelpBox
              showAutomatically={!!isMemberLite}
              resetsAt={getResetsAtText(resetsAt)}
              progress={progress}
              limit={limit}
              upgradeEvent="Plans from homeview"
              trackingContext={trackingContext}
              onClickLearnMore={trackClickLearnMore}
              isMemberLite={isMemberLite}
            >
              <BannerProgressBar
                progress={progress}
                limit={limit}
                onClick={trackClickProgressBar}
              />
            </ReachedLimitHelpBox>
          ) : (
            <NormalHelpBox
              resetsAt={getResetsAtText(resetsAt)}
              progress={progress}
              limit={limit}
              upgradeEvent="Plans from homeview"
              trackingContext={trackingContext}
              isMemberLite={isMemberLite}
            >
              <BannerProgressBar
                progress={progress}
                limit={limit}
                onClick={trackClickProgressBar}
              />
            </NormalHelpBox>
          )
        }
      </NewEngagementLimitBannerDataHandler>
    );
  }

  return (
    <CurrentEngagementLimitBanner
      upgradeEvent="Plans from homeview"
      trackingContext={trackingContext}
    />
  );
}

export default DashboardEngagementLimitBanner;
