import { useState, useEffect } from 'react';
import { LocalStorage } from '@mentimeter/storage';
import { useSpring } from 'react-spring';
import { getEngagementLimitProgress } from '@mentimeter/paywalls-data-hooks';
import { LAST_ENGAGMENT_PROGESS_KEY } from './constants';

export interface StoredEngagementProgress {
  progress: number;
}

export function useProgressBarAnimation(progress: number, limit: number) {
  const [previousProgressByLimit] = useState(
    getEngagementLimitProgress(getStoredPreviousProgress(), limit),
  );
  const progressByLimit = getEngagementLimitProgress(progress, limit);

  useEffect(() => {
    LocalStorage.setJSONItem({
      type: 'functional',
      key: LAST_ENGAGMENT_PROGESS_KEY,
      value: { progress },
    });
  }, [progress]);

  const style = useSpring({
    from: { width: previousProgressByLimit + '%' },
    to: { width: progressByLimit + '%' },
    delay: 1200,
    config: { tension: 250, friction: 18 },
  });

  return {
    style,
    progressByLimit,
  };
}

export function getStoredPreviousProgress() {
  const storedProgress = LocalStorage.getJSONItem<StoredEngagementProgress>(
    LAST_ENGAGMENT_PROGESS_KEY,
  );

  return storedProgress?.progress ?? 0;
}
